@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&family=Play&display=swap');


body{
    font-family: 'Merriweather Sans', sans-serif;
    font-family: 'Play', sans-serif;
}








/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(53, 53, 53); 
  }

  .lgin{
    background: rgba(150, 150, 150, 0.15);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10px );
    -webkit-backdrop-filter: blur( 10px );
    border-radius: 6px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* -----------------for quill---------------- */

ul{
  list-style-type: disc;
}

.ql-indent-1{
  margin-left: 10px;
}

.ql-indent-2{
  margin-left: 15px;
}

.ql-indent-3{
  margin-left: 20px;
}

.ql-indent-4{
  margin-left: 25px;
}



.ql-size-10px{
  font-size: 10px;
}
.ql-size-12px{
  font-size: 12px;
}
.ql-size-14px{
  font-size: 14px;
}
.ql-size-16px{
  font-size: 16px;
}
.ql-size-18px{
  font-size: 18px;
}
.ql-size-20px{
  font-size: 20px;
}


.ql-size-12px{
  font-size: 22px;
}
.ql-size-24px{
  font-size: 24px;
}
.ql-size-26px{
  font-size: 26px;
}
.ql-size-28px{
  font-size: 28px;
}

.ql-size-30px{
  font-size: 30px;
}
.ql-size-32px{
  font-size: 32px;
}
.ql-size-34px{
  font-size: 34px;
}
.ql-size-36px{
  font-size: 36px;
}
.ql-size-38px{
  font-size: 38px;
}

.ql-size-40px{
  font-size: 40px;
}
.ql-size-42px{
  font-size: 42px;
}
.ql-size-44px{
  font-size: 44px;
}
.ql-size-46px{
  font-size: 46px;
}
.ql-size-48px{
  font-size: 48px;
}
.ql-size-50px{
  font-size: 50px;
}